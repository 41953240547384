<template>
  <div class="articleDetail gc-000" v-if="infoForm.id">
    <a-button @click="back" icon="left">返回</a-button>
    <div class="gb-fff gmv-10 gpv-15 gph-15 g-flex g-justify-space-between">
      <div class="g-flex">
        <img v-if="infoForm.cover_img" :src="qiniuDomain+infoForm.cover_img" class="gh-100p gw-100p gmr-10">
        <img v-else src="@/assets/imgs/img4.png" class="gh-100p gw-100p gmr-10">
        <div class="g-flex g-flex-column g-justify-space-between gpv-5">
          <div>
            <span class="gfs-18 gfw-b gmr-10">{{infoForm.name}}</span>
            <span @click="changeCollectStatus(1)" class="iconfont icon-noCollect g-pointer" style="font-size:18px" v-if="!infoForm.isCollect"></span>
            <span @click="changeCollectStatus(0)" class="iconfont icon-collect gc-red g-pointer" style="font-size:18px" v-else></span>
          </div>
          <div>
            <span>{{subjectList[infoForm.subject_type-1]}}</span>
            <span>·{{infoForm.question_type_name}}</span>
            <span>·{{infoForm.classify_name}}</span>
          </div>
          <div class="gc-aaa">
            更新时间：{{infoForm.update_date}}
          </div>
        </div>
      </div>
      <div>
        <a-button class="gmr-10" v-if="articleList.indexOf($route.query.id)!=0 && articleList.length>0" @click="prev">上一题</a-button>
        <a-button class="gmr-10" v-if="articleList.indexOf($route.query.id)!=articleList.length-1 && articleList.length>0" @click="next">下一题</a-button>
        <a-button class="gmr-10" @click="postArticlePaperAnswer(1)" v-if="infoForm.type!=1">未斩</a-button>
        <a-button class="gmr-10" type="primary" @click="postArticlePaperAnswer(0)" v-else>已斩</a-button>
        <a-button @click="toArticleHomework" v-if="infoForm.practice_list.length>0">课后练习</a-button>
      </div>
    </div>
    <a-row :gutter="10">
      <a-col :span="12">
        <div class="gb-fff gpv-15 gph-15 g-flex g-justify-space-between">
          <div>原文</div>
          <AudioPlayer v-if="hasListenRole && infoForm.audioList.length>0" :audioList="infoForm.audioList" key="articleAudio" id="articleAudio"></AudioPlayer>
          <span v-else>暂无</span>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="gb-fff gpv-15 gph-15 g-flex g-justify-space-between">
          <div>讲解</div>
          <div>
            <AudioPlayer v-if="hasListenRole && infoForm.explainList.length>0" :audioList="infoForm.explainList" key="explainAudio" id="explainAudio"></AudioPlayer>
            <span v-else>暂无</span>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- <div class="gb-000 gpv-10 gph-10 g-flex g-justify-space-between" style="color:rgb(255, 185, 0)" v-if="!hasListenRole">
      <div>试听一分钟，升级收听完整音频</div>
      <div @click="$router.push('/personal/vip')" class="g-pointer">
        <span>升级</span>
        <a-icon type="right" />
      </div>
    </div> -->
    <a-row :gutter="10">
      <a-col :span="12">
        <div class="gb-fff gpv-15 gph-15 " style="height:550px;overflow-y: auto;">
          <div>
            <div v-for="(item,index) in infoForm.paragraph_list" :key="index">
              <div v-for="(child,childIdx) in item.content_list" :key="childIdx" v-html="child.content">
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="gb-fff gpv-15 gph-15" style="height:550px;overflow-y: auto;">
          <div v-if="hasTranslateRole">
            <div v-for="(item,index) in infoForm.paragraph_list" :key="index">
              <div v-for="(child,childIdx) in item.content_list" :key="childIdx" v-html="child.translate_content">
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mask">
            </div>
            <div class="gmt-10 gtc">
              <a-button shape="round" type="primary" @click="$router.push('/personal/vip')">加入会员查看</a-button>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

  </div>
</template>
<script>
import {
  getImportantDetail,
  getStudyArticleList,
  postArticlePaperAnswer,
  putCollectArticle,
} from "@/api/articlePaper";
import { subjectList } from "@/utils/common";
import { mapGetters } from "vuex";
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
export default {
  components: { AudioPlayer },
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)),
      infoForm: {},
      articleList: [],
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain", "hasTranslateRole", "hasListenRole"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.paperId = this.$route.query.paperId;
    this.getImportantDetail(this.id);

    if (this.paperId) {
      this.getStudyArticleList();
    }
  },
  methods: {
    //上一题
    prev() {
      let curArticleIndex = this.articleList.indexOf(this.id);
      if (curArticleIndex != 0) {
        curArticleIndex = curArticleIndex - 1;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            id: this.articleList[curArticleIndex],
          },
        });
        this.id = this.articleList[curArticleIndex];
        this.getImportantDetail();
      }
    },
    //下一题
    next() {
      let curArticleIndex = this.articleList.indexOf(this.id);
      if (curArticleIndex < this.articleList.length - 1) {
        curArticleIndex = curArticleIndex + 1;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            id: this.articleList[curArticleIndex],
          },
        });
        this.id = this.articleList[curArticleIndex];
        this.getImportantDetail();
      }
    },
    //答题
    postArticlePaperAnswer(type) {
      let param = {
        important_word_id: this.id,
        important_word_paper_id: this.paperId,
        is_right: type,
      };
      this.infoForm.type = type;
      postArticlePaperAnswer(param);
    },
    //练习列表
    getStudyArticleList() {
      getStudyArticleList(this.paperId).then((res) => {
        if (res.code == 200) {
          this.articleList = res.data;
        }
      });
    },
    //机经详情
    getImportantDetail() {
      getImportantDetail({ id: this.id }).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          let audioList = [];
          let explainList = [];
          data.paragraph_list.map((item) => {
            if (item.content_list && item.content_list.length > 0) {
              //原文音频
              let audioArr = item.content_list.filter(
                (item) => item.auto_audio
              );
              audioArr = audioArr.map((item) => {
                return {
                  id: item.id,
                  audio: item.auto_audio,
                  audio_time: item.auto_audio_time,
                };
              });
              audioList.push(...audioArr);
              //讲解音频
              let explainArr = item.content_list.filter(
                (item) => item.explain_audio
              );
              explainArr = explainArr.map((item) => {
                return {
                  id: item.id,
                  audio: item.explain_audio,
                  audio_time: item.explain_audio_time,
                };
              });
              explainList.push(...explainArr);
            }
          });
          this.infoForm = {
            ...data,
            audioList,
            explainList,
          };
        }
      });
    },
    back() {
      this.$router.back();
    },
    //收藏
    changeCollectStatus(status) {
      this.infoForm.isCollect = status;
      putCollectArticle({
        important_word_id: this.id,
        is_collect: status,
      });
    },
    //课后练习
    toArticleHomework() {
      if (this.infoForm.practice_list.length > 0) {
        this.$router.push({
          path: "/articleHomework",
          query: {
            id: this.id,
          },
        });
      } else {
        this.$message.error("无课后练习");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.articleDetail {
  /deep/ p {
    margin-bottom: 0;
  }
  .mask {
    width: 100%;
    height: 80px;
    background: linear-gradient(#fff, #565050);
  }
}
</style>