<template>
  <div class="AudioPlayer">
    <div v-for="(item, index) in audioList" :key="index" v-show="currenPlayIndex==index">
      <audio :id="item.id+id" :src="qiniuDomain + item.audio" @timeupdate="onTimeupdate" @ended="onTimeupdateEnd"></audio>
      <div class="audio-times">
        <div class="line">
          <i :class="{'listen-materials': true, play: !playing, suspend: playing}" @click="playAudio(1)"></i>
          <div class="innner" :style="{ width: Math.min(((currentTime * 100) / totalTime),100) + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: {
      type: String,
      default: "audio",
    },
    audioList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      playing: false, // 音频是否正在播放
      currentTime: 0, // 当前播放时间
      totalTime: 0, // 音频总时长
      currenPlayIndex: 0, // 当前播放音频的索引
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain"]), // 获取七牛云音频域名
  },
  methods: {
    // 播放或暂停音频
    playAudio(type) {
      if (type == 1) {
        this.playing = !this.playing;
      }
      let playing = this.playing;
      this.$nextTick(() => {
        let paudio = document.getElementById(
          this.audioList[this.currenPlayIndex].id + this.id
        );
        if (paudio) {
          if (playing) {
            paudio.play();
          } else {
            paudio.pause();
          }
        }
      });
    },
    // 监听音频播放进度
    onTimeupdate(e) {
      let num = 0; // 记录当前之前音频的总时长
      for (let i = 0; i < this.currenPlayIndex; i++) {
        num += this.audioList[i].audio_time || 0;
      }
      this.currentTime = num + Number(e.currentTarget.currentTime.toFixed(2));
    },
    // 音频播放完毕后的处理
    onTimeupdateEnd() {
      if (this.currenPlayIndex < this.audioList.length - 1) {
        this.currenPlayIndex++; // 切换到下一个音频
        if (this.audioList[this.currenPlayIndex].audio) {
          this.playAudio(2); // 播放下一个音频
        } else {
          this.onTimeupdateEnd(); // 如果下一个音频不存在，继续寻找
        }
      } else {
        this.playing = false; // 如果是最后一个音频，重置状态
        this.currenPlayIndex = 0;
        this.currentTime = 0;
      }
    },
  },
  watch: {
    audioList: {
      deep: true,
      immediate: true,
      handler(val) {
        // 初始化音频总时长
        if (val.length > 0) {
          let num = 0;
          val.map((item) => {
            if (typeof item.audio_time == "number") {
              num += item.audio_time;
            }
          });
          this.totalTime = num;
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
.AudioPlayer {
  .audio-times {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .line {
      position: relative;
      width: 200px;
      height: 8px;
      border-radius: 4px;
      background: #ccc;
      .listen-materials {
        position: absolute;
        left: -40px;
        top: -9px;
        margin-top: 0px;
        margin-left: 10px;
        display: inline-block;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &.play {
          background: url(~@/assets/imgs/img5.png) no-repeat center center;
          background-size: 24px;
        }
        &.suspend {
          background: url(~@/assets/imgs/img6.png) no-repeat center -2px;
          background-size: 30px;
        }
      }
      .innner {
        border-radius: 4px;
        height: 8px;
        background: #000;
      }
    }
  }
}
</style>